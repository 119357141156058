import React from "react";
import Card from "../components/Card";
import "../styles/HomePage.css";
import acapits from "../information/rodoTextShareholders";

const RODOShareholders = () => {
  const acapit = acapits.map((item, index) => (
    <p key={index} className="firstAcapit">
      {item}
    </p>
  ));
  return (
    <Card>
      <div className="textShareholders">
        <div className="titleContainer">
          <h1 className="title">RODO</h1>
        </div>
        {acapit}
      </div>
    </Card>
  );
};

export default RODOShareholders;
