const acapits = [
  "Obowiązek informacyjny dla Akcjonariuszy Baltis Investment spółka akcyjna oraz innych osób uprawnionych z akcji i pełnomocników Akcjonariuszy",
  " Zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwane dalej RODO) uprzejmie informujemy, że:",
  "1. Administratorem Pani/Pana danych osobowych (ADO) jest: Baltis Investment spółka akcyjna z siedzibą w Gdyni, ul. Hryniewickiego 6c/17, 81 – 340 Gdynia, KRS: 0000732223, NIP: 522-307-65-57.",
  "2. Nasze dane kontaktowe to: sekretariat@baltis.pl, adres korespondencyjny: , ul. Hryniewickiego 6c/17, 81 – 340 Gdynia.",
  "3. Z inspektorem ochrony danych może się Pani/Pan skontaktować się pod adresem e-mail: IOD_BALTIS@baltis.pl, lub korespondencyjnie na adres „Inspektor ochrony danych Porta”, ul. Hryniewickiego 6c/17, 81-340 Gdynia lub pod adresem ADO (pkt 2).",
  "4. Pani/Pana dane osobowe będą przetwarzane w celach zrealizowania uprawnień lub spełnienia obowiązku wynikającego z przepisów prawa określonych w Kodeksie Spółek Handlowych (Dz.U. z 2000. Nr 94 poz. 1037 z późn. zm.), w szczególności art. 341 par 1, art. 362 par 1 pkt. 8, art. 406, art. 407, Statucie spółki w związku z art. 6 ust. 1 lit c RODO , tj. wypełnienie obowiązków prawnych ciążących na ADO oraz w związku z art. 6 ust. 1 lit. f RODO tj. realizacja prawnie usprawiedliwionych interesów Administratora, przy czym prawnie uzasadnionym interesem ADO jest obrona i dochodzenie roszczeń. ",
  "5. Jeżeli jest Pani/Pan akcjonariuszem uprawnionym z akcji imiennych, zastawnikiem lub użytkownikiem tych akcji, to podanie danych  osobowych jest niezbędne do wpisania Pani/Pana do księgi akcyjnej, ustalenia Pani/Pana uprawnień i wykonywania przez Panią/Pana swoich praw z akcji. Jeżeli jest Pani/Pan pełnomocnikiem akcjonariusza lub innej osoby uprawnionej z akcji Spółki,  podanie  danych osobowych jest niezbędne do wykonywania przez Panią/Pana w imieniu mocodawcy przysługujących mu praw z akcji.",
  "6. Odbiorcą danych osobowych są: ",
  "a. pozostali akcjonariusze w zakresie realizacji ich żądań dostępu do danych akcjonariuszy,",
  "b. pracownicy oraz współpracownicy ADO upoważnieni do przetwarzania danych osobowych w związku z realizacją obowiązków służbowych,",
  "c. kancelarie prawne i notarialne w zakresie prowadzenia spraw Spółki związanych z akcjami,",
  "d. podmiotom prowadzącym rejestr akcjonariuszy w imieniu Spółki,",
  "e. podmiotom świadczącym usługi pocztowe lub kurierskie,",
  "f. podmiotom współpracującym z ADO w zakresie realizacji obowiązków ADO, w szczególności usługodawcy IT, usługodawcy hostingu, dostawcy oprogramowania i serwisów internetowych, etc. Podmioty te przetwarzają dane na podstawie osobnych umów ze Spółką i zgodnie z poleceniami Spółki.",
  "7. Dane będą przetwarzane przez okres niezbędny do realizacji celów przetwarzania wskazanych w pkt 4: ",
  "a. w zakresie wypełniania obowiązków prawnych ciążących na ADO, dane będą przetwarzane przez okres wypełniania tych obowiązków wskazanych w przepisach prawa,",
  "b. w zakresie realizacji uzasadnionych interesów ADO, dane będą przetwarzane do chwili pozytywnego rozpatrzenia wniesionego przez Panią/Pana sprzeciwu wobec przetwarzania danych lub przedawnienia potencjalnych roszczeń.",
  "8. Informujemy o przysługującym prawie do ",
  "a. dostępu do swoich danych osobowych,",
  "b. sprostowania swoich danych osobowych,",
  "c. żądania ograniczenia przetwarzania swoich danych,",
  "d. usunięcia danych, jeżeli nie jest realizowany żaden inny cel przetwarzania,",
  "e. prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych w związku z przetwarzaniem danych przez ADO w prawnie uzasadnionym interesie ADO.",
  "9. Z uprawnień może Pani/Pan skorzystać kontaktując się korespondencyjnie lub e-mail IOD_BALTIS@baltis.pl z IOD (pkt 2, 3).",
  "10. Informujemy o prawie wniesienia skargi do organu nadzorczego. W Polsce organem takim jest Prezes Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa).",
  "11. ADO nie przekazuje Pani/Pana danych osobowych do państwa trzeciego lub organizacji międzynarodowej.",
  "12. ADO nie przetwarza Pani/Pana danych osobowych w sposób zautomatyzowany w związku z podejmowanymi decyzjami, w tym nie dokonuje profilowania.",
];

export default acapits;
