import React from "react";
import Card from "../components/Card";
import LinkShareholders from "../components/LinkShareholders";
import informations from "../information/shareholdersText";
import "../styles/HomePage.css";

const ShareholdersPage = () => {
  const information = informations.map((item, index) => (
    <p key={index} className="firstAcapit">
      {item}
    </p>
  ));
  return (
    <Card>
      <div className="info text">
        <div className="titleContainer">
          <h1 className="title">Dla akcjonariuszy</h1>
        </div>
        {information}
        <LinkShareholders />
      </div>
    </Card>
  );
};

export default ShareholdersPage;
