import React from 'react';
import '../styles/Link.css';
import {NavLink} from 'react-router-dom';
import Pdf from '../information/InformacjaStrategiiPodatkowej.pdf'

const LinkTaxStrategyPDF = () => {
  const text = 'Informacja o strategii podatkowej – PDF';
  return (
      <div className="link">
        <NavLink to={Pdf} target="_blank" download>
          {text}
        </NavLink>
      </div>
  );
};

export default LinkTaxStrategyPDF;
