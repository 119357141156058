import React from "react";
import "../styles/Link.css";
import { NavLink } from "react-router-dom";

const LinkShareholders = () => {
  const text =
    "Obowiązek informacyjny dla Akcjonariuszy Baltis Investment spółka akcyjna oraz innych osób uprawnionych z akcji i pełnomocników Akcjonariuszy  ";
  return (
    <div className="link">
      <NavLink to={"/RODO-dla-akcjonariuszy"}>{text}</NavLink>
      {/* <a href="https://magdalenaromala.github.io/RODO">{text}</a> */}
    </div>
  );
};

export default LinkShareholders;
