import React from "react";
import Card from "../components/Card";
import RODO from "../components/Link";
import "../styles/HomePage.css";

const ContactPage = () => {
  return (
    <Card>
      <div className="text">
        <div className="titleContainer">
          <h1 className="title">Dane spółki </h1>
        </div>
        <div className="flex">
          <div>
            <p>
              <span>Baltis Ivestment Spółka Akcyjna</span>
            </p>
            <p>ul. Hryniewickiego 6C/17, 81-340 Gdynia</p>
          </div>
          <div>
            <p>
              <span>tel: </span>
              <a className="contact" href="tel: 58 558 67 30">
                58 558 67 30
              </a>
            </p>
            <p>
              <span>e-mail: </span>
              <a className="contact" href="mailto:sekretariat@baltis.pl">
                sekretariat@baltis.pl
              </a>
            </p>
          </div>
        </div>
        <p className="description">
          Spółka zarejestrowana w Sądzie Rejonowym Gdańsk-Północ, VIII Wydział
          Gospodarczy, KRS 0000732223; NIP 522-307-65-57; REGON 365913338.
          Kapitał zakładowy 638.566.300 zł, w całości opłacony.
        </p>
        Inspektor Ochrony Danych Osobowych Arkadiusz Reiter,
        <span> e-mail: </span>
        <a className="contact" href="mailto:IOD_BALTIS@baltis.pl">
          IOD_BALTIS@baltis.pl
        </a>
        <p className="description">
          Z Inspektorem można się kontaktować we wszystkich sprawach związanych
          z przetwarzaniem danych osobowych przez Spółkę oraz w zakresie
          realizacji praw osób, których dane dotyczą wynikających z
          obowiązujących przepisów prawa. W pozostałych sprawach prosimy o z
          sekretariatem Spółki.
        </p>
        <RODO />
      </div>
    </Card>
  );
};

export default ContactPage;
