import React from "react";
import { Route, Switch } from "react-router-dom";
import ShareholdersPage from "../pages/ShareholdersPage";
import HomePage from "../pages/HomePage";
import ContactPage from "../pages/ContactPage";
import RODO from "../pages/RODO";
import RODOShareholders from "../pages/RODOShareholders";
import TaxStrategy from '../pages/TaxStrategy';

const Page = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/kontakt" exact component={ContactPage} />
        <Route path="/dla-akcjonariuszy" exact component={ShareholdersPage} />
        <Route path="/RODO" exact component={RODO} />
        <Route path="/RODO-dla-akcjonariuszy" exact component={RODOShareholders} />
        <Route path="/informacja-o-strategii-podatkowej" exact component={TaxStrategy} />
      </Switch>
    </>
  );
};

export default Page;
