import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import "../styles/Navigation.css";
import logo from "../images/logo.png";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  text-align: center;

  li {
    padding: 10px 5px;
  }

  @media (max-width: 900px) {
    flex-flow: column nowrap;
    background-color: white;
    box-shadow: ${({ open }) => (open ? "0 5px 25px #95989a" : "")};
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 200px;
    width: 250px;
    padding: 3rem 0 0;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;
const list = [
  { name: "O FIRMIE", path: "/", exact: true },
  { name: "DANE SPÓŁKI", path: "/kontakt", exact: true },
  { name: "DLA AKCJONARIUSZY", path: "/dla-akcjonariuszy", exact: true },
  { name: "INFORMACJA O STRATEGII PODATKOWEJ", path: "/informacja-o-strategii-podatkowej", exact: true },
];

const Navigation = ({ open }) => {
  const menu = list.map((item) => (
    <li key={item.name}>
      <NavLink to={item.path} exact={item.exact ? item.exact : false}>
        {item.name}
      </NavLink>
    </li>
  ));
  return (
    <div className="header">
      <a href="/">
        <img className="logo" src={logo} alt="Baltis Investment" />
      </a>
      <nav className="main">
        <Ul open={open}>{menu}</Ul>
      </nav>
    </div>
  );
};

export default Navigation;
