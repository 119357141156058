import React from "react";
import "../styles/Link.css";
import { NavLink } from "react-router-dom";

const Link = () => {
  const text="Klauzula informacyjna RODO w zakresie przetwarzania danych"
  return (
    <div className="link">
        <NavLink to={'/RODO'} >
        {text}
      </NavLink>
      {/* <a href="https://magdalenaromala.github.io/RODO">{text}</a> */}
    </div>
  );
};

export default Link;
