import React from "react";
import Card from "../components/Card";
import "../styles/HomePage.css";
import LinkTaxStrategyPDF from '../components/LinkTaxStrategyPDF';

const TaxStrategy = () => {

  return (
      <Card>
        <div className="text">
          <div className="titleContainer">
            <h1 className="title">Informacja o strategii podatkowej</h1>
          </div>
          <p className="firstAcapit">
            Poniżej publikujemy informację o strategii podatkowej, którą stosuje Spółka.
          </p>
        </div>
        <LinkTaxStrategyPDF/>
      </Card>
  );
};

export default TaxStrategy;
