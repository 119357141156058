import React from 'react';
import Card from '../components/Card';
import Link from '../components/Link';
import logoPorta from '../images/logoPorta.png';
import logoInvestkomfort from '../images/logoInvestkomfort.png';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
      <Card>
        <div className="text">
          <div className="titleContainer">
            <h1 className="title">O firmie</h1>
          </div>
          <p>
            Baltis Investment S.A. jest spółką holdingową, która jest
            wspólnikiem
            między innymi w dwóch spółkach prawa handlowego:
          </p>
          <div className="description">
            <div className="name">
              <p>
                <span>• Porta KMI</span> Poland Spółka Akcyjna,
              </p>
              <p>
                <span>• Invest Komfort</span> Spółka Akcyjna Spółka Komandytowa,
              </p>
            </div>
            <div className="logoContainer">
              <a
                  href="https://www.porta.com.pl/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img className="companyLogo" src={logoPorta} alt="Porta"/>
              </a>
              <a
                  href="https://www.investkomfort.pl/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    className="companyLogo logoInvest"
                    src={logoInvestkomfort}
                    alt="Invest Komfort"
                />
              </a>
            </div>
          </div>
          <p>
            Baltis Investment S.A świadczy usługi w ramach centrum usług
            wspólnych
            na rzecz spółek z Grupy kapitałowej. Podstawowym przedmiotem
            działalności jest: Działalność Firm Centralnych i Holdingów, z
            wyłączeniem Holdingów Finansowych. Spółka świadczy między innymi
            usługi związane z ochroną danych osobowych, sporządzaniem
            dokumentacji
            cen transferowych, ESG i ochroną znaków towarowych. Ponadto,
            planowane jest rozszerzenie zakresu usług wspólnych świadczonych
            przez
            Baltis Investment S.A. Z kolei, spółki z Grupy kapitałowej rozwijają
            działalność gospodarczą w dotychczasowych segmentach działalności,
            tj.
            działalności deweloperskiej oraz produkcyjnej w zakresie produkcji
            drzwi.
          </p>
          <Link/>
        </div>
      </Card>
  );
};

export default HomePage;
