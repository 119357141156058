const acapits = [
  " Obowiązek informacyjny w związku z realizacją obowiązków wynikających z postanowień Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej zwanym „RODO”) informujemy, że:",
  "1. Administratorem Państwa danych osobowych jest BALTIS INVESTMENT Spółka Akcyjna z siedzibą w Gdyni, nr KRS 0000732223 nr NIP 522-307-65-57. (dalej zwanym „ADO”).",
  "2. Adres siedziby oraz adres korespondencyjny ADO to: ul. Hryniewickiego 6c/17, 81-340 Gdynia, adres e-mail: sekreteriat@baltis.pl",
  "3. ADO w celu usprawnienia obsługi zgłoszeń składanych w związku z przetwarzanymi przez siebie danymi osobowymi osób fizycznych, powołał Inspektora Ochrony Danych Osobowych. Wszelkie zgłoszenia związane z przetwarzaniem Państwa danych osobowych należy kierować bezpośrednio do Inspektora Ochrony Danych Osobowych. Zgłoszeń mogą Państwo dokonywać za pomocą poczty elektronicznej bądź pocztą tradycyjną. Adres e-mail Inspektora Ochrony Danych Osobowych: IOD_BALTIS@baltis.pl Adres pocztowy Inspektora Ochrony Danych Osobowych: Inspektor Ochrony Danych Osobowych Baltis Investment S.A., ul. Hryniewickiego 6c/47, 81-340 Gdynia",
  "4. Informujemy, że w każdym przypadku przysługuje Państwu prawo żądania: dostępu do swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania lub wniesienia sprzeciwu wobec ich przetwarzania.",
  "5. Odbiorcą Państwa danych osobowych mogą być uprawnione organy publiczne lub podmioty, z którym ADO współpracuje w celu realizacji zadań, do których się zobowiązał (np. kancelarie prawne, kancelarie podatkowe, etc.).",
  "6. Dane osobowe mogą być ujawniane podmiotom współpracującym z ADO na podstawie umowy powierzenia przetwarzania zgodnie z art. 28 RODO (np. współpracownicy, hostingodawcy, usługodawcy IT, biura rachunkowe podmioty świadczące usługi kurierskie lub pocztowe, podmioty świadczące usługi archiwizacji dokumentacji etc.)",
  "7. Cele oraz podstawy prawne przetwarzania danych osobowych:",
  "a. Cel: wykonanie umowy lub podjęcie działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy. Podstawa prawna: art. 6 ust. 1 lit.b) RODO. Podanie danych osobowych jest warunkiem zawarcia umowy, a brak ich podania uniemożliwia jej zawarcie;",
  "b. Cel: prawnie uzasadniony interes ADO. Podstawa prawna: art. 6 ust. 1 lit. f) RODO. Prawnie uzasadnionym interesem ADO jest przetwarzanie danych osobowych osób fizycznych w celu ustalenia, obrony, dochodzenia roszczeń wynikających z umowy oraz zarządzanie przedsiębiorstwem. Podanie danych osobowych przez osobę fizyczną nie jest konieczne, ponieważ ADO jest już w posiadaniu tych danych;",
  "c. Cel: wypełnienie obowiązków prawnych ciążących na ADO wynikających z powszechnie obowiązujących przepisów prawa – w zakresie obowiązków księgowych, podatkowych i archiwizacyjnych. Podstawa prawna: art. 6 ust. 1 lit. c) RODO. Podanie danych osobowych jest wymaganiem obowiązujących przepisów prawa.",
  "8. W przypadku, gdy podstawą prawną przetwarzania Państwa danych osobowych jest zgoda mogą Państwo cofnąć zgodę na przetwarzanie swoich danych osobowych w każdym momencie. Cofnięcie zgody pozostaje bez wpływu na zgodność z prawem dla przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.",
  "9. Okres czasu, przez który Państwa dane osobowe będą przetwarzane, jest uzależniony od celu, w jakim zostały one zgromadzone, tj.:",
  "a. w przypadku osób fizycznych będących stroną umowy zawartej z ADO bądź osób fizycznych zmierzających do zawarcia umowy (prowadzących negocjacje handlowe) z ADO – co najmniej do czasu przedawnienia roszczeń każdej ze stron umowy lub przez okres wynikający z powszechnie obowiązujących przepisów prawa,",
  "b. w przypadku osób fizycznych, które wyraziły zgodę na przetwarzanie swoich danych osobowych – do czasu cofnięcia zgody,",
  "c. w przypadku osób fizycznych, których dane przetwarza ADO w prawnie uzasadnionym interesie – do czasu pozytywnego rozpatrzenia przez ADO wniesionego przez Państwo sprzeciwu wobec takiego przetwarzania.",
  "10. ADO nie podejmuje zautomatyzowanych decyzji dotyczących Państwa danych osobowych w tym nie dokonuje profilowania Państwa danych osobowych.",
  "11. ADO nie przekazuje Państwa danych osobowych do państwa trzeciego lub organizacji międzynarodowej.",
  "12. W każdy przypadku przysługuje Państwu prawo do złożenia skargi bezpośrednio do Urzędu Ochrony Danych Osobowych, ul Stawki 2, 00-193 Warszawa.",
];

export default acapits;
