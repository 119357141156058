import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  const text = "© Baltis Investment. Wszelkie prawa zastrzeżone";
  return (
    <div className="footer">
      <p>{text}</p>
    </div>
  );
};

export default Footer;
